(function ($) {
  // Opens add review area on click
  $("#review-anchor").on("click", function (e) {
    e.preventDefault();
    $(this).parent().toggleClass("active");
    $(this).next(".review-form-wrap").fadeToggle(50);
  });

  // // Opens read review area on click
  $("#readreview-anchor").on("click", function (e) {
    e.preventDefault();
    $(this).parent().toggleClass("active");
    $("ul.product-review-list").fadeToggle(50);
  });

  // Text about the reviews
  $("#textarea-trigger").on("click", function (e) {
    e.preventDefault();
    $(this).parent().toggleClass("active");
    $(this).next(".textarea-reviews").fadeToggle(50);
  });

  // Reviews area open on page load
  $("#readreview-anchor").parent().addClass("active");
  $(".ul.product-review-list").show();

  // Review form areas open on desktop and mobile page load
  $("#review-anchor").parent().addClass("active");
  $("#review-anchor").next(".review-form-wrap").show();

  // Text areas open on desktop page load
  if ($(window).width() >= 1024) {
    $("#textarea-trigger").parent().addClass("active");
    $("#textarea-trigger").next(".textarea-reviews").show();
  }

  // Hide text area on mobile page load
  else if ($(window).width() < 1024) {
    $("#textarea-trigger").parent().removeClass("active");
    $("#textarea-trigger").next(".textarea-reviews").hide();
  }

  // sets width of star area (font-size differs from browser to browser)
  var content = $("#star-ratings-css-bottom").width();
  $("#star-ratings-css").width(content);
  $("#star-ratings-css").addClass("visible");
})(jQuery);
