(function ($) {
  var _ = console.log;

  $('.checkout-tab-links a').on('click', function (e) {
    e.preventDefault();
    var target = $(e.target).attr('data-target');
    var hide = $(e.target).siblings().attr('data-target');

    $(e.target).addClass('active');
    $(e.target).siblings().removeClass('active');

    $(target).show();
    $(hide).hide();
  });

  $("body").on(
    "keyup",
    "#checkout-billing-form input[required]",
    debounce((e) => checkInput(e), 500)
  );

  $("body").on("change", "#checkout-billing-form input[required]", (e) =>
    checkInput(e)
  );

  function checkInput(e) {
    var button = $("body #checkout-billing-form button[type=submit]");

    if (validateRequiredFields()) {
      button.prop("disabled", false);
    } else {
      button.prop("disabled", true);
    }
  }

  function validateRequiredFields() {
    var validFields = true;
    var form = $("body #checkout-billing-form");
    var requiredInputs = form.find("input[required]");
    requiredInputs.each(function (i, input) {
      var value = $(input).val();
      var type = $(input).attr("data-type");
      if (!type) {
        type = $(input).attr("type");
      }

      if (value == "" || !validateDataType(value, type)) {
        $(input).addClass("has-error");
        validFields = false;
      } else {
        $(input).removeClass("has-error");
      }
    });

    return validFields;
  }

  function validateDataType(value, type) {
    if (type == "email") {
      return validateEmail(value);
    }

    if (type == "postal-code") {
      return validatePostalCode(value);
    }

    if (type == "phone") {
      return validatePhone(value);
    }

    return true;
  }

  function validateEmail($email) {
    var regx =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regx.test($email);
  }

  function validatePostalCode(value) {
    var regx = /^\d{4}$/;
    return regx.test(value);
  }

  function validatePhone(value) {
    var phone = value.replace(/\s/g, '');
    var regx = /^(0047|\+47|47)?[2-9]\d{7}$/;

    return regx.test(phone);
  }

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  $("body").on('submit', "#checkout-billing-form", function(e) {
    e.preventDefault();
    submitForm();
  });

  function submitForm() {
    if (validateRequiredFields()) {
      var form  = $('body #checkout-billing-form');
      var url = form.attr('action');
      var data = {
        'action'     : form.find('input[name=action]').val(),
        'nonce'      : form.find('input[name=nonce]').val(),
        'first_name' : form.find('input[name=first_name]').val(),
        'last_name'  : form.find('input[name=last_name]').val(),
        'postal_code': form.find('input[name=postal_code]').val(),
        'email'      : form.find('input[name=email]').val(),
        'phone'      : form.find('input[name=phone]').val(),
        'address'    : form.find('input[name=address]').val(),
        'address_co' : form.find('input[name=address_co]').val(),
        'city'       : form.find('input[name=city]').val()
      }

      $.ajax({
        url: url,
        method: 'POST',
        data: data,
        beforeSend: function() {
          $('body').addClass('product-loading');
        },
        success: function(response) {
          if (response.data.vipps_redirect_url) {
            // redirect to response url
            window.location.replace(response.data.vipps_redirect_url);
          } else {
            // show error message
            $('body').removeClass('product-loading');
          }
        },
        error: function(error) {
          // show error message
          $('body').removeClass('product-loading');
        }
      });
    }
  }
})(jQuery);
