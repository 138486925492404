import "owl.carousel";
// function to update super deal products
window.updateSuperdealProducts = function() {
  $.ajax({
    url: adminURL,
    type: "POST",
    data: {
      action: "load_superdeal_products"
    },
    beforeSend: function() {
      $("#superdeal-products").addClass("fetching");
    },
    success: function(response) {
      $("#superdeal-products")
        .removeClass("fetching")
        .html(response);
      initSuperDealsGallery();
    },
    error: function(error) {
      // console.log(error);
    }
  });
};

// function to initialize super deals gallery
window.initSuperDealsGallery = function() {
  var slider = $("#superdeal-products .super-deals-gallery");
  if (window.innerWidth > 767) {
    slider.owlCarousel({
      center: false,
      items: 2,
      loop: false,
      margin: 8,
      nav: true,
      navText: ["", ""],
      responsive: {
        768: {
          items: 4,
        },
        1280: {
          items: 5
        }
      }
    });
  }
};

// initialize super deal gallery on page load
initSuperDealsGallery();
