(function($) {
  if (!window.initBambuserLiveShopping){
    window.initBambuserLiveShopping = function(item) { window.initBambuserLiveShopping.queue.push(item) }; window.initBambuserLiveShopping.queue = [];
    var scriptNode = document.createElement('script');
    scriptNode['src'] = 'https://lcx-embed.bambuser.com/rfsu/embed.js';
    document.body.appendChild(scriptNode);
  }

  var lang =  $('html').attr('lang');

  window.onBambuserLiveShoppingReady = (player) => {
    player.configure({
      currency: 'KR',
      locale: lang,
      buttons: {
        checkout: player.BUTTON.MINIMIZE,
        dismiss: player.BUTTON.MINIMIZE,
        product: player.BUTTON.MINIMIZE,
      }
    });
  }

  var liveShoppingShows = $('.js-liveshopping-cta');
  liveShoppingShows.each(function (i, cta) {
    var showId = cta.getAttribute('data-liveshopping-id');
    window.initBambuserLiveShopping({
      showId: showId,
      node: cta,
      type: "overlay",
    });
  });
})(jQuery);
