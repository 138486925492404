import Cookies from "js-cookie";

var optSection = $("#opt-section");
var optEnable = optSection.attr("data-opt-enable")
  ? parseInt(optSection.attr("data-opt-enable"))
  : 0;
if (optEnable) {
  // show opt-in form if user leave tab
  $(window).blur(function() {
    var optCookie = Cookies.get("opt-in");
    if (!optCookie) {
      $("#opt-section").addClass("opt-in");
    }
  });

  // show popup if user is inactive for 2 min on current tab
  var idleTime = 0;
  setInterval(timerIncrement, 60000); // 30 sec

  // reset idle time if any mouse/keyboard activity
  $(window).on("mousemove keypress", function() {
    idleTime = 0;
  });

  // show opt-in if use not opted-in and scroll about 50%
  $(document).on("scroll resize", function(e) {
    var optSection = $("#opt-section");
    var optPosition = optSection.attr("data-opt-percent");
    var optCookie = Cookies.get("opt-in");
    if (!optSection.hasClass("opt-in") && !optCookie) {
      var docHeight =
        $(document).height() - parseFloat(optSection.outerHeight());
      var height = optPosition
        ? parseFloat(optPosition) * 0.01 * docHeight
        : docHeight / 2;
      var position = $(window).scrollTop();
      if (position >= height / 2) {
        optSection.addClass("opt-ready");
      } else {
        optSection.removeClass("opt-ready");
      }
      if (position >= height) {
        optSection.addClass("opt-in");
      }
    }
  });

  // close opt-in and store opted-in cookies
  $(document).on("click", ".close-opt-in", function(e) {
    e.preventDefault();
    Cookies.set("opt-in", 1, { expires: 7 });
    $("#opt-section").removeClass("opt-in");
    $("#opt-section").removeClass("opt-ready");
  });

  // store opted-in cookies when click on link
  $(document).on("click", ".link-opt-in", function(e) {
    Cookies.set("opt-in", 1, { expires: 7 });
  });
}

// function to clear timeout
function timerIncrement() {
  idleTime = idleTime + 1;
  var optCookie = Cookies.get("opt-in");
  if (idleTime == 2 && !optCookie) {
    // 2 min of inactivity
    $("#opt-section").addClass("opt-in");
  }
}
