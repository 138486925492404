(function($) {
  if ($("#datepicker").length) {
    // update calendar on changing date and
    $(".pregnancy-form").on("change", "#datepicker", function() {
      refreshData();
    });

    // on form submit
    $(".pregnancy-form").on("submit", function(e) {
      e.preventDefault();
      refreshData();
      $(".pregnancy-calendar-section").slideDown();
    });
  }

  // refresh data based on choosen calendar date
  function refreshData() {
    var avgPregDays = 280;
    var pregDate = $(".pregnancy-form #datepicker").val();
    var splitDate = pregDate.split(".");
    var pDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
    var nDate = new Date(pDate);
    var today = new Date();
    today.setDate(today.getDate() - 1);
    nDate.setDate(nDate.getDate() + avgPregDays + 1);
    var weeks = 0;
    var days = 0;
    var diffDays = 0;
    // init var for translation
    var langDays = null;
    // Check which language
    if (siteLang === "NO") {
      langDays = " dager";
    } else {
      langDays = " dagar";
    }
    if (today.valueOf() >= pDate.valueOf()) {
      var timeDiff = Math.abs(pDate.getTime() - today.getTime());
      diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      days = diffDays;
      if (diffDays >= 7) {
        weeks = Math.floor(diffDays / 7);
        days = diffDays % 7;
      }
    }
    var pregChance = days + langDays;
    if (diffDays) {
      pregChance = weeks + " + " + days + langDays;
    }
    var deliveryDate = $.datepicker
      .setDefaults($.datepicker.regional["sv"])
      .formatDate("d M yy", nDate);

    $("#pregnancy-chance").text(pregChance);
    $("#pregnancy-date").text(deliveryDate);
  }
})(jQuery);
