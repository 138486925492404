import Cookies from "js-cookie";

(function($) {
  if ($("#datepicker").length) {
    // Set language localization and vars
    if (siteLang === "NO") {
      $.datepicker.setDefaults($.datepicker.regional["no"]);
      var dateformat = "dd.mm.yy";
      var close = "Lukk";
      var today = "i dag";
      var january = "januar";
      var february = "februar";
      var mars = "mars";
      var april = "april";
      var may = "mai";
      var june = "juni";
      var july = "juli";
      var august = "august";
      var september = "september";
      var october = "oktober";
      var november = "november";
      var december = "desember";
      var Sun = "Søn";
      var Mon = "Man";
      var Tue = "Tir";
      var Wed = "Ons";
      var Thu = "Tor";
      var Fri = "Fre";
      var Sat = "Lør";
      var Week = "Uke";
    } else {
      $.datepicker.setDefaults($.datepicker.regional["sv"]);
      var dateformat = "dd.mm.yy";
      var close = "Stäng";
      var today = "Idag";
      var january = "januari";
      var february = "februari";
      var mars = "mars";
      var april = "april";
      var may = "maj";
      var june = "juni";
      var july = "juli";
      var august = "augusti";
      var september = "september";
      var october = "oktober";
      var november = "november";
      var december = "december";
      var Sun = "Sön";
      var Mon = "Mån";
      var Tue = "Tis";
      var Wed = "Ons";
      var Thu = "Tor";
      var Fri = "Fre";
      var Sat = "Lör";
      var Week = "Ve";
    }

    var datePickerOptions = {
      dateFormat: dateformat,
      closeText: close,
      currentText: today,
      monthNames: [
        january,
        february,
        mars,
        april,
        may,
        june,
        july,
        august,
        september,
        october,
        november,
        december
      ],
      dayNamesMin: [Sun, Mon, Tue, Wed, Thu, Fri, Sat],
      weekHeader: Week
    };

    // add datepicker to date field
    $("#datepicker*").datepicker(datePickerOptions);

    refreshCalendar();

    function refreshCalendar(nextMonth) {
      // average ovulation days is 14 days and +/- 2 days
      // previous and most system have used +/- 3 days

      // get default date - current date is default date
      var dateInput = $(".ovu-form #datepicker").val();
      if (dateInput && typeof dateInput !== undefined && dateInput !== "") {
        var splitDate = dateInput.split(".");
        var lastDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
        var firstMensDate = new Date(lastDate);

        // if date is given and it is next/prev month
        if (nextMonth) {
          var nextMonthDate = Cookies.get("nextMonthDate");
          nextMonthDate = nextMonthDate.split(".");
          lastDate = new Date(
            nextMonthDate[2],
            nextMonthDate[1] - 1,
            nextMonthDate[0]
          );
        } else {
          Cookies.set("nextMonthDate", dateInput);
        }

        // get constants
        var days = parseInt($(".ovu-form #mens-cycle").val());
        var phase = -3;
        var avgDays = 14;
        var standardDays = 28;
        var resultDays = 6;
        var currentOffset = days - standardDays;
        var offsetDays = avgDays + currentOffset + phase;

        // starting date
        var nextStartDate = new Date(lastDate);
        nextStartDate.setDate(lastDate.getDate() + offsetDays);
        nextStartDate.setHours(0, 0, 0, 0);

        // create default date from next start date,
        // choose 1st day from start date month
        var setDate = new Date(nextStartDate);
        setDate.setDate(setDate.getDate() + 1);
        setDate.setDate(1);

        // end date
        var nextEndDate = new Date(nextStartDate);
        nextEndDate.setDate(nextStartDate.getDate() + resultDays);
        nextEndDate.setHours(0, 0, 0, 0);

        // calculate start and end date for previous month
        var prevDate = new Date(lastDate);
        prevDate.setDate(prevDate.getDate() - days);

        var prevStartDate = new Date(prevDate);
        prevStartDate.setDate(prevStartDate.getDate() + offsetDays);
        prevStartDate.setHours(0, 0, 0, 0);

        var prevEndDate = new Date(prevStartDate);
        prevEndDate.setDate(prevEndDate.getDate() + resultDays);
        prevEndDate.setHours(0, 0, 0, 0);

        // create arrays of dates
        var nextDateArray = getDates(nextStartDate, nextEndDate);
        var prevDateArray = getDates(prevStartDate, prevEndDate);

        $("#ovu-calendar").datepicker("destroy");

        var fertile = 0;

        $("#ovu-calendar").datepicker({
          setDate: setDate,
          defaultDate: setDate,
          gotoCurrent: true,
          showOtherMonths: true,

          monthNames: [
            january,
            february,
            mars,
            april,
            may,
            june,
            july,
            august,
            september,
            october,
            november,
            december
          ],
          dayNamesMin: [Sun, Mon, Tue, Wed, Thu, Fri, Sat],

          onChangeMonthYear: function(year, month) {
            var changedDate = new Date(year, month - 1);
            var currentDate = Cookies.get("nextMonthDate");
            currentDate = currentDate.split(".");
            currentDate = new Date(
              currentDate[2],
              currentDate[1] - 1,
              currentDate[0]
            );

            Cookies.remove("nextMonthDate");
            // calculate fist mens date for previous and next month
            if (currentDate.valueOf() > changedDate.valueOf()) {
              currentDate.setDate(currentDate.getDate() - days);
            } else {
              currentDate.setDate(currentDate.getDate() + days);
            }
            Cookies.set(
              "nextMonthDate",
              $.datepicker.formatDate("dd.mm.yy", currentDate)
            );

            // refresh calendar with prev/next events
            refreshCalendar(1);
          },
          beforeShowDay: function(date) {
            date.setHours(0, 0, 0, 0);
            if (
              date.valueOf() === lastDate.valueOf() &&
              date.valueOf() === firstMensDate.valueOf()
            ) {
              return [1, "ui-date mens-date"];
            }

            var classes = "ui-date";

            // check if current date is in prevDateArray
            for (var i = 1; i < prevDateArray.length; i++) {
              if (prevDateArray[i].valueOf() === date.valueOf()) {
                classes += " prev-fertile-rate-" + i;
              }
            }

            for (var i = 1; i < nextDateArray.length; i++) {
              if (nextDateArray[i].valueOf() === date.valueOf()) {
                classes += " fertile-rate-" + i;
              }
            }

            return [1, classes];
          }
        });

        fertile = 0;
        $("#ovu-calendar").datepicker("refresh");

        // update text
        var dateText = "";
        var nextStartDate2 = nextStartDate;
        nextStartDate2.setDate(nextStartDate2.getDate() + 1);
        var nextEndDate2 = nextEndDate;
        // - 2 instead of -1 because we don't show day 5 anymore
        nextEndDate2.setDate(nextEndDate2.getDate() - 2);

        var startDateText = $.datepicker
          .setDefaults($.datepicker.regional["sv"])
          .formatDate("d M", nextStartDate2);
        var endDateText = $.datepicker
          .setDefaults($.datepicker.regional["sv"])
          .formatDate("d M", nextEndDate2);
        // check month, if same month display only once
        if (
          $.datepicker.formatDate("M", nextStartDate2) ===
          $.datepicker.formatDate("M", nextEndDate2)
        ) {
          startDateText = $.datepicker.formatDate("d", nextStartDate2);
        }
        dateText = startDateText + " - " + endDateText;
        $("#pregnancy-chance").text(dateText);
      }
    }

    // update calendar on changing date and
    $(".ovu-form").on("change", "#datepicker, #mens-cycle", function() {
      refreshCalendar();
    });

    // on form submit
    $(".ovu-form").on("submit", function(e) {
      e.preventDefault();
      refreshCalendar();
      $(".ovu-calendar-section, .ovu-pregnancy-text").slideDown();
    });

    // Returns an array of dates between the two dates
    function getDates(startDate, endDate) {
      var dates = [],
        currentDate = startDate,
        addDays = function(d) {
          var date = new Date(this.valueOf());
          date.setDate(date.getDate() + d);
          return date;
        };
      while (currentDate < endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }

      return dates;
    }
  } // end check if datepicker exist
})(jQuery);
