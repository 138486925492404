// newletter subscription send email
$(document).ready(function($) {
  $("#signup-newsletter-klaviyo").on("click", function(e) {
    e.preventDefault();
    var button = $("#signup-newsletter-klaviyo");
    var email = button.data("email");
    var nonce = button.data('nonce');
    // init vars for alerts
    var signupSuccess = null;
    var signupFailed = null;

    // Check which language
    if (siteLang === "NO") {
      signupSuccess =
        "Registrering vellykket! Takk for at du velger å abonnere på RFSUs nyhetsbrev! Sjekk e-posten din for å bekrefte din registrering.";
      signupFailed = "Oops! Noe gikk feil. Prøv igjen senere.";
    } else {
      signupSuccess =
        "Grattis! Du har framgångsrikt tecknat på vårt nyhetsbrev. Kontrollera din e-post för att bekräfta din prenumeration.";
      signupFailed = "Hoppsan! Något gick fel. Vänligen kolla igen senare.";
    }

    $("body").addClass("product-loading");

    $.ajax({
      url: adminURL,
      type: "POST",
      data: {
        email: email,
        action: "klaviyo_email_list",
        nonce: nonce,
      },
      success: function(response) {
        if (response.success) {
          alert(signupSuccess);
        } else {
          alert(signupFailed);
        }

        $("body").removeClass("product-loading");
      },
      error: function(error) {
        alert(signupFailed);
        $("body").removeClass("product-loading");
      }
    });
  });
});
