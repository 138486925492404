// ------------------------------------
// sets the header to fixed on scroll
// -------------------------------------

$(document).ready(function ($) {
  var waypoint = new Waypoint({
    element: document.getElementById("trigger-fixed-meny"),
    handler: function () {
      $(".header-main-wrap, .search-wrap").toggleClass("fixed");
      $("body").toggleClass("fixed-menu");
    },
    offset: -25,
  });
});
