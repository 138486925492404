// Only on swedish site
if (siteLang === "SE") {
  var TEH_EVENT = new Array();
  TEH_EVENT["closetimer"] = 0;
  TEH_EVENT["contentSet"] = 0;

  var mywidth = 238;
  var myheight = 269;

  var target_reachable = false;

  function TEH_EVENT_CreateIframe() {
    var element = document.createElement("iframe");
    var teJsHost = "https:" == document.location.protocol ? "https" : "http";
    element.src = teJsHost + "://www.tryggehandel.se/verifystore.php?id=1791";
    element.style.display = "none";
    element.style.position = "absolute";
    element.style.width = mywidth + "px";
    element.style.height = myheight + "px";
    element.style.border = "0px";
    element.style.zIndex = "0";
    element.style.borderRadius = "12px";
    element.frameBorder = "0";
    element.scrolling = "no";
    element.allowTransparency = "true";

    // Make sure target is reachable
    target_image = new Image();
    target_image.onload = function() {
      target_reachable = true;
    };
    target_image.src =
      teJsHost +
      "://www.tryggehandel.se/images/trans.gif?t=" +
      new Date().getTime();

    if (element.addEventListener) {
      element.addEventListener("mouseover", TEH_EVENT_Show, false);
      element.addEventListener("mouseout", TEH_EVENT_HideTime, false);
    } else {
      element.attachEvent("onmouseover", TEH_EVENT_Show);
      element.attachEvent("onmouseout", TEH_EVENT_HideTime);
    }

    return element;
  }
  function findPosX(obj) {
    var curleft = 0;
    if (obj.offsetParent) {
      while (obj.offsetParent) {
        curleft += obj.offsetLeft;
        obj = obj.offsetParent;
      }
    } else if (obj.x) curleft += obj.x;

    return curleft;
  }
  function findPosY(obj) {
    var curtop = 0;
    if (obj.offsetParent) {
      while (obj.offsetParent) {
        curtop += obj.offsetTop;
        obj = obj.offsetParent;
      }
    } else if (obj.y) curtop += obj.y;

    return curtop;
  }

  var TEH_EVENT_Show_i = 0;
  function TEH_EVENT_Show(event) {
    if (!TEH_EVENT["iframe"]) {
      TEH_EVENT["iframe"] = TEH_EVENT_CreateIframe();
      document.body.appendChild(TEH_EVENT["iframe"]);
    }

    if (target_reachable == true) {
      TEH_EVENT_Show_popup();
    } else if (TEH_EVENT_Show_i < 10) {
      TEH_EVENT_Show_i++;
      TEH_EVENT["opentimer"] = window.setTimeout(TEH_EVENT_Show, 300);
    }
  }

  function TEH_EVENT_Show_popup(event) {
    TEH_EVENT_CancelClose();
    if (!TEH_EVENT["iframe"]) {
      TEH_EVENT["iframe"] = TEH_EVENT_CreateIframe();
      document.body.appendChild(TEH_EVENT["iframe"]);
    }
    if (TEH_EVENT["iframe"].style.display == "none") {
      var yy = document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
      var px = GetWndCliSiz("Width");
      var py = GetWndCliSiz("Height");
      var llogo = document.getElementById("TEH_LOGOTYPE");
      var tx = llogo.width;
      var ty = llogo.height;
      var lx = findPosX(llogo);
      var ly = findPosY(llogo);
      var totalwidth = tx + lx + mywidth;
      var totalheight = ly + ty + myheight + yy;
      if (totalwidth > px) {
        var newposx = lx - mywidth + 20;
        TEH_EVENT["iframe"].style.left = newposx + "px";
      } else {
        TEH_EVENT["iframe"].style.left = lx + tx + "px";
      }

      if (window.scrollX != undefined) {
        var xDiff = lx - window.scrollX;
        //If popup comes left out of Window
        if (xDiff < mywidth) {
          xDiff = window.scrollX;
          TEH_EVENT["iframe"].style.left = self.pageXOffset + xDiff + "px";
        }
      }

      if (window.scrollY != undefined) {
        yDiff = ly - window.scrollY;
        //If popup comes under Window
        if (window.scrollY < myheight) {
          yDiff = yDiff - myheight + 30;
        }
        //If popup comes over window
        if (yDiff < myheight) {
          yDiff = 0;
        }
        TEH_EVENT["iframe"].style.top = self.pageYOffset + yDiff + "px";
      } else {
        yDiff = ly - document.body.parentNode.scrollTop;
        TEH_EVENT["iframe"].style.top =
          document.documentElement.scrollTop + yDiff + "px";
      }
    }
    TEH_EVENT["iframe"].style.display = "inline";
  }
  function TEH_EVENT_Hide() {
    if (TEH_EVENT["iframe"]) TEH_EVENT["iframe"].style.display = "none";
  }
  function TEH_EVENT_ShowTime() {
    TEH_EVENT["opentimer"] = window.setTimeout(TEH_EVENT_Show, 300);
  }
  function TEH_EVENT_HideTime() {
    if (TEH_EVENT["iframe"]) {
      TEH_EVENT["closetimer"] = window.setTimeout(TEH_EVENT_Hide, 500);
    } else {
      window.clearTimeout(TEH_EVENT["opentimer"]);
    }
  }
  function TEH_EVENT_CancelClose() {
    if (TEH_EVENT["closetimer"]) {
      window.clearTimeout(TEH_EVENT["closetimer"]);
      TEH_EVENT["closetimer"] = null;
    }
  }
  function GetWndCliSiz(s) {
    var db,
      y = window["inner" + s],
      sC = "client" + s,
      sN = "number";
    if (typeof y == sN) {
      var y2;
      return (db = document.body) && typeof (y2 = db[sC]) == sN && y2 && y2 <= y
        ? y2
        : (db = document.documentElement) &&
          typeof (y2 = db[sC]) == sN &&
          y2 &&
          y2 <= y
        ? y2
        : y;
    }
    return (db = document.documentElement) && (y = db[sC])
      ? y
      : document.body[sC];
  }
  if (document.addEventListener) {
    document
      .getElementById("TEH_LOGOTYPE")
      .addEventListener("mouseover", TEH_EVENT_Show, false);
    document
      .getElementById("TEH_LOGOTYPE")
      .addEventListener("mouseout", TEH_EVENT_HideTime, false);
  } else {
    document
      .getElementById("TEH_LOGOTYPE")
      .attachEvent("onmouseover", TEH_EVENT_Show);
    document
      .getElementById("TEH_LOGOTYPE")
      .attachEvent("onmouseout", TEH_EVENT_HideTime);
  }
}
