import "@grubersjoe/slide-menu";
var _ = require("lodash");

/**
 * Mobile menu triggered on SlideMenu
 *
 * @return {void}
 */
$(document).ready(function ($) {
  // Init vars for translations
  var backToText = null;
  // Check which language
  if (siteLang === "NO") {
    backToText = "Tilbake til ";
  } else {
    backToText = "Tillbaka till ";
  }
  const menuElement = document.getElementById("slide-menu-mobile");
  const menu = new SlideMenu(menuElement, {
    position: "left",
    showBackLink: true,
    submenuLinkAfter: '<span class="submenu-link-after"></span>',
    backLinkBefore: '<span class="submenu-link-before"></span>' + backToText,
  });

  // Close mobile menu. Debounce to not trigger resize too often.
  function closeMenu() {
    if ($(window).width() >= 1280) {
      menu.close();
    }
  }
  jQuery(window).on("resize", _.debounce(closeMenu, 300));

  $(
    "ul.main-menu > li > .megamenu-wrap > .layout.wide > ul.sub-menu > li.hide-top-level > a"
  ).addClass("hidden");
  $("<div class='menu-divider'></div>").insertBefore("li.inspiration");
  $("<div class='menu-divider'></div>").insertAfter("li.sex-education");

  // Only on swedish site
  if (siteLang === "SE") {
    // Add class to mobile menu object before menu-divider
    $("#nav-icon").on("click", function (e) {
      $(".menu-divider").map(function (e) {
        return $(this)
          .prevAll(".menu-item")
          .first()
          .addClass("no-border-bottom");
      });
    });
  }
});
