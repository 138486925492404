$(document).ready(function($) {
  // foldout coupon input field
  $(".cart-summary, .coupon-wrapper").on(
    "click",
    ".coupon-foldout",
    function() {
      $(".coupon-form").toggleClass("active");
    }
  );

  // check if coupon code is valid
  $(document).on("submit", "form#coupon", function(e) {
    e.preventDefault();
    var code = $(this)
      .find("input[name=coupon]")
      .val();
    validateCoupon(code);
  });

  // Coupon removed button
  $(".cart-savings .remove_coupon").on(
    "click",
    function() {
      //alert('remove coupon clicked');
      removeCoupon();
    }
  );

});

// -------------------------------
// validate coupon code
// -------------------------------
function validateCoupon(code) {
  // init vars for translation
  alertError = null;
  theCode = null;
  notValid = null;
  notValidAnymore = null;

  // Check which language
  if (siteLang === "NO") {
    alertError = "Oops! Noe gikk feil. Prøv igjen senere.";
    theCode = "Koden ";
    notValid = " er ikke gyldig";
    notValidAnymore = " är inte lenger giltig";
  } else {
    alertError = "Något gick fel, försök igen";
    theCode = "Koden ";
    notValid = " är inte giltig";
    notValidAnymore = " är inte längre giltig";
  }
  $.ajax({
    url: adminURL,
    method: "POST",
    data: {
      action: "validate_coupon",
      code: code,
    },
    beforeSend: function() {
      $("body").addClass("product-loading");
    },
    success: function(response) {
      // if the code is invalid
      if (response == "badcode") {
        alert(theCode + code + notValid);
      }

      // if the code is expired
      if (response == "expired" || response == "used_limit") {
        alert(theCode + code + notValidAnymore);
      }
      window.location.reload();
    },
    error: function() {
      alert(alertError);
      $("body").removeClass("product-loading");
    }
  });
}

// -------------------------------
// Remove coupon code
// -------------------------------
function removeCoupon() {

  $.ajax({
    url: adminURL,
    method: "POST",
    data: {
      action: "remove_coupon",
    },
    beforeSend: function() {
      $("body").addClass("product-loading");
    },
    success: function(response) {
      window.location.reload();
    },
    error: function() {
      alert(alertError);
      $("body").removeClass("product-loading");
    }
  });
}
