$(document).ready(function ($) {
  if ($(window.matchMedia("(min-width: 1024px)").matches)) {
    var tabtrigger = $("#product-tabs li:first .product-tabs__item-trigger");
    var siblings = $("#product-tabs li:first .product-tabs__item-trigger")
      .parent()
      .siblings();
    var content =
      $(tabtrigger).next(".product-tabs__item-content").height() + 120;

    // Add class active to first tab (on desktop only)
    $(tabtrigger).parent().addClass("active active-first");
    $(tabtrigger)
      .next(".product-tabs__item-content")
      .addClass("active active-first")
      .fadeIn(200);

    // Set height of container to ul (on desktop only)
    $("#product-tabs").height(content);
  }
  // Toggles the tabs on click
  $("#product-tabs .product-tabs__item .product-tabs__item-trigger").on(
    "click",
    function () {
      var tabtrigger = $(this);
      var siblings = $(this).parent().siblings();
      var content =
        $(tabtrigger).next(".product-tabs__item-content").height() + 120;
      siblings.each(function () {
        $(this)
          .find(".product-tabs__item-content")
          .fadeOut(200)
          .removeClass("active active-first");
      });
      $(tabtrigger).next(".product-tabs__item-content").fadeToggle(200);
      $(this).parent().toggleClass("active");
      $(siblings).removeClass("active active-first");

      // Set height of container to ul (on desktop only)
      if ($(window.matchMedia("(min-width: 1024px)").matches)) {
        $("#product-tabs").height(content);
        if (!$(tabtrigger).parent().hasClass("active")) {
          // If no tab selected - reset height
          $("#product-tabs").removeAttr("style");
        }
      }
    }
  );

  // When Click on rating count at top of page
  $(".rating-count, .own-review").on("click", function () {
    var content =
      $("#product-tabs .product-tabs__item.review .product-tabs__item-trigger")
        .next(".product-tabs__item-content")
        .height() + 120;
    $("#product-tabs .product-tabs__item.review .product-tabs__item-trigger")
      .parent()
      .addClass("active");
    $("#product-tabs .product-tabs__item.review .product-tabs__item-content")
      .addClass("active")
      .fadeIn(200);
    $(
      "#product-tabs .product-tabs__item:not(.review) .product-tabs__item-trigger"
    )
      .parent()
      .removeClass("active");
    $(
      "#product-tabs .product-tabs__item:not(.review) .product-tabs__item-content"
    )
      .removeClass("active")
      .fadeOut(200);

    // Set height of container to ul (on desktop only)
    if ($(window.matchMedia("(min-width: 1024px)").matches)) {
      $("#product-tabs").height(content);
    }
  });

  $(".own-review").on("click", function () {
    $("#review-anchor").parent().addClass("active");
    $("#review-anchor").next(".review-form-wrap").show();
  });

  // Scroll to anchor on click (reviews)
  $(document).on("click", 'a[href^="#"]', function (e) {
    // target element id
    var id = $(this).attr("href");
    // target element
    if (!id) {
      console.log('yeyeye returned');
      return;
    }

    var $id = $(id);
    if ($id.length === 0) {
      return;
    }

    // prevent standard hash navigation (avoid blinking in IE)
    e.preventDefault();
    // top position relative to the document
    if ($(window).width() > 1279) {
      var pos = $id.offset().top - 150;
    } else {
      var pos = $id.offset().top - 100;
    }
    // animated top scrolling
    $("body, html").animate({ scrollTop: pos });
  });
});

$(window).on("load resize", function (e) {
  e.preventDefault();
  var tabitemfirstactive = $(".product-tabs__item.active.active-first");
  var tabcontentfirstactive = $(
    ".product-tabs__item-content.active.active-first"
  );
  var tabitems = $(".product-tabs__item.active");
  var tabitemfirst = $("#product-tabs li:first .product-tabs__item-content");
  var tabtriggerfirst = $("#product-tabs li:first .product-tabs__item-trigger");
  var content = $(tabitemfirst).height() + 120;

  if (
    $(tabitemfirstactive).length &&
    window.matchMedia("(max-width: 1023px)").matches
  ) {
    $(tabitemfirstactive.removeClass("active active-first"));
    $(tabcontentfirstactive.removeClass("active active-first"));
  }
  if (!$(tabitems).length && window.matchMedia("(min-width: 1024px)").matches) {
    $(tabitemfirst.addClass("active active-first").removeAttr("style"));
    $(tabtriggerfirst.parent().addClass("active active-first"));
    $("#product-tabs").height(content) + 120;
  }
});
