(function ($) {
  var form = $(".js-validate-rating-form");
  form.on("submit", function (event) {
    event.preventDefault();
    var valid_author = false;
    var valid_email = false;
    var valid_rating = false;
    var valid_comment = false;
    var valid_grecaptcha = false;
    var valid_gdpr = false;

    // check if name is filled in
    var author = form.find(".validate input[name=author]");
    var authorErrorMessage = author.parents(".validate").find(".error-message");
    if (author.val() === "") {
      valid_author = false;
      authorErrorMessage.css("display", "block");
    } else {
      valid_author = true;
      authorErrorMessage.hide();
    }

    // check if google reCaptcha is clicked
    var grecaptcha = form.find(".validate .g-recaptcha");
    var valid_grecaptcha = form.find("#g-recaptcha-response").val();
    var captchaErrorMessage = grecaptcha.parents(".validate").find(".error-message");
    if (!valid_grecaptcha) {
      captchaErrorMessage.css("display", "block");
      captchaErrorMessage = false;
    } else {
      captchaErrorMessage.hide();
      captchaErrorMessage = true;
    }

    // check if email is filled in
    var email = form.find(".validate input[name=email]");
    var emailErrorMessage = email.parents(".validate").find(".error-message");
    if (email.val() === "") {
      valid_email = false;
      emailErrorMessage.css("display", "block");
    } else {
      valid_email = true;
      emailErrorMessage.hide();
    }

    // check if stars radio is checked
    var rating = form.find(".validate input[name=rating]");
    var ratingErrorMessage = rating.parents(".validate").find(".error-message");
    if (!rating.is(":checked")) {
      valid_rating = false;
      ratingErrorMessage.css("display", "block");
    } else {
      valid_rating = true;
      ratingErrorMessage.hide();
    }

    // check if review message is typed
    var reviewText = form.find(".validate [name=comment]");
    var reviewErrorMessage = reviewText
      .parents(".validate")
      .find(".error-message");
    if (reviewText.val() === "") {
      valid_comment = false;
      reviewErrorMessage.css("display", "block");
    } else {
      valid_comment = true;
      reviewErrorMessage.hide();
    }

    //check if GDPR checkbox is checked
    var gdpr = form.find(".validate input[type=checkbox]");
    var gdprErrorMessage = gdpr.parents(".validate").find(".error-message");
    if (gdpr.prop("checked") == true) {
      valid_gdpr = true;
      gdprErrorMessage.hide();
      console.log("checked");
    }
    else if (gdpr.prop("checked") == false) {
      valid_gdpr = false;
      gdprErrorMessage.css("display", "block");
      console.log("NOT checked");
    }

    if (valid_author && valid_email && valid_rating && valid_comment && valid_grecaptcha && valid_gdpr) {
      this.submit();
    }
  });

  // remove error message when rating star is selected
  form.find(".validate input[name=rating]").on("change", function () {
    var rating = $(this);
    var ratingErrorMessage = rating.parents(".validate").find(".error-message");
    if (rating.is(":checked")) {
      ratingErrorMessage.hide();
    } else {
      ratingErrorMessage.css("display", "block");
    }
  });

  // when user starts typing review, remove error message
  form.find(".validate [name=comment]").on("keyup", function () {
    var review = $(this);
    var reviewErrorMessage = review.parents(".validate").find(".error-message");
    if (review.val() !== "" && review.val().length >= 2) {
      reviewErrorMessage.hide();
    } else {
      reviewErrorMessage.css("display", "block");
    }
  });
})(jQuery);
