import $ from "jquery";

/**
 * Check if products in customers cart is still available in, stock.
 * If not, we remove them from cart and alert the customer.
 *
 * Only run this check on the checkout page, hence the check of body class "woocommerce-checkout",
 */
class StockCheck {
  constructor() {
    /**
     * Check stock via ajax on checkout page
     */
    this.initCheckStock();

    /**
     * Check if user is inactive on checkout page
     *
     * @param {int} Amount of (milliseconds * seconds * minutes) used as inactivity
     * @param {func} Callback to run upon inactivity
     */
    this.checkInactivity(1000 * 60 * 30, () => this.catchInactivity());

    /**
     * Check if user has be redirected due to inactviity
     */
    this.handleInactivity();
  }

  /**
   * Initiates a stock check on checkout page
   *
   * @param {int}
   * @param {func}
   *
   * @return {void}
   */
  checkInactivity(milliseconds, callback) {
    if ($("body.woocommerce-checkout").length > 0) {
      /**
       * Set timeout
       *
       * @param {func}
       * @param {int}
       */
      let wait = setTimeout(callback, milliseconds);

      document.onmousemove = document.mousedown = document.mouseup = document.onkeydown = document.onkeyup = document.focus = () => {
        /** Clear the timeout on document event  */
        clearTimeout(wait);

        /**
         * Set timeout
         *
         * @param {func}
         * @param {int}
         */
        wait = setTimeout(callback, milliseconds);
      };
    }
  }

  /**
   * If inactivity catched, handle it
   *
   * @param {void}
   */
  catchInactivity() {
    /**
     * Get the previous visited page
     *
     * @var {string}
     */
    let previous_page = document.referrer;

    /**
     * Redirect to previous page with
     * an action search param
     */
    window.location.href = `${previous_page}?action=inactive_redirect`;
  }

  /**
   * If inactivity catched, handle it
   *
   * @param {void}
   */
  handleInactivity() {
    /**
     * Get search params from URL
     *
     * @var {string}
     */
    var urlParams = new URLSearchParams(window.location.search);

    // Define alert variables
    var alertInactive = null;

    // Check which language
    if (siteLang === "NO") {
      alertInactive =
        "Du har vært inaktiv en stund, og derfor blitt sendt vekk fra kassen. Gå tilbake til kassen igjen for å fullføre ditt kjøp.";
    } else {
      alertInactive =
        "Du har varit inaktiv en längre tid och därför skickats från kassan. Detta för att våra produkter är väldigt populära och snabbt tar slut i lager. Vänligen gå till kassan igen för att fortsätta med ditt köp.";
    }

    if (urlParams.get("action") === "inactive_redirect") {
      /**
       * Remove all search param from current URL
       *
       * @var {string}
       */
      var cleanURL = window.location.href.replace(window.location.search, "");

      /**
       * Push the clean URL
       */
      window.history.pushState({ path: cleanURL }, "", cleanURL);

      alert(alertInactive);
    }
  }

  /**
   * Initiates a stock check on checkout page
   */
  initCheckStock() {
    /**
     * Run checkStock on page load
     */
    this.checkStock();

    /**
     * Run checkStock every 20th seconds
     */
    setInterval(() => this.checkStock(), 20000);
  }

  /**
   * Ajax call to check customers cart vs stock status
   *
   * @return {void}
   */
  checkStock() {
    if ($("body.woocommerce-checkout").length > 0) {
      /**
       * Set the action to be fired
       *
       * @var {object} data
       */
      var data = {
        action: "wc_ajax_check_cart_stock"
      };

      /**
       * Init ajax call
       *
       * @return {array|null}
       */
      $.ajax({
        url: adminURL,
        method: "POST",
        data: data,
        success: function(response) {
          if (response.length != 0) {
            /**
             * Get all removed products and create a string
             *
             * @var {array} products
             */
            const products = response.map(response => ` ${response}`);

            // Define alert variables
            var alertOutOfStock = null;

            // Check which language
            if (siteLang === "NO") {
              alertOutOfStock =
                "Følgende produkter ble akkurat kjøpt av en annen kunde og er nå dessverre utsolgt:";
            } else {
              alertOutOfStock =
                "Följade produkter blev precis köpta av en annan kund och är tyvärr nu slut i lager:";
            }

            /**
             * Alert customer and reload page in order to rerender checkout
             */
            alert(alertOutOfStock + ` ${products}`);

            /**
             * Reload window in order to update checkout
             */
            window.location.reload();
          }
        },

        error: function(error) {
          console.log(error);
        }
      });
    }
  }
}

new StockCheck();
