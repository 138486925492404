$(document).ready(function($) {
  // -------------------------------
  // Close addtocart modal
  // -------------------------------
  $(".close-modal, .close-modal-link").on("click", function() {
    $("body").removeClass("modal-addtocart-active");
    $("#related-product-wrap").empty();
    if ($("body").hasClass("varukorg")) {
      window.location.reload();
    }
  });

  $(".modal").on("click", function(e) {
    e.stopPropagation();
  });

  if ($("body").hasClass("varukorg")) {
    $(".modal-background").on("click", function() {
      window.location.reload();
    });
  }

  $(document.body).click(function(event) {
    if (!$(event.target).hasClass("add-to-cart-group-products")) {
      $("body").removeClass("modal-addtocart-active");
    }
  });

  $(document).keyup(function(e) {
    if (e.keyCode === 27) {
      if ($(".modal-addtocart-active").length) {
        $("body").removeClass("modal-addtocart-active");
      }
    }
  });
});
