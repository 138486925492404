$(document).ready(function($) {
  var postnordLocations;

  // -------------------------------
  // Fetch available service points, closest to postal code user inputs
  // -------------------------------
  $(document).on("submit", "#servicepoints", function(e) {
    e.preventDefault();
    var form = $(this);
    var nonce = form.find("[name=nonce]").val();
    var selected_address = form.find("[name=agent_id]");
    var option_id = form.find("[name=agent_option_id]").val();
    if (selected_address.val() && postnordLocations) {
      var selected_address_id = selected_address.val();
      $.each(postnordLocations, function(i, agent) {
        if (selected_address_id == agent.id) {
          /**
           * If address is selected from dropdown, show klarna checkout form
           */
          choosePickupPoint(nonce, agent, option_id);
          return false;
        }
      });
    } else {
      var postal_code = $("#servicepoints input[name=postalcode]").val();
      var nonce = $("#servicepoints input[name=nonce]").val();

      var data = {
        action: "unifaun_checkout_request",
        postal_code: postal_code,
        nonce: nonce,
      };

      // init var for translation
      var alertError = null;
      var badPostalCode = null;

      // Check which language
      if (siteLang === "NO") {
        alertError = "Oops! Noe gikk feil. Prøv igjen senere.";
        badPostalCode = "Finner ikke postnummer";
      } else {
        alertError = "Något gick fel, försök igen";
        badPostalCode = "Hittade inte postkod";
      }

      // initiate ajax add to cart
      $.ajax({
        url: adminURL,
        method: "POST",
        data: data,
        beforeSend: function() {
          $("body").addClass("product-loading");
          $("#select-servicepoints")
            .html("")
            .hide();

          // hide error message if any
          $(".postalcode-section__error").hide();
        },
        success: function(response) {
          $("body").removeClass("product-loading");

          // if something goes wrong with fetching data
          if (response === "error") {
            alert(alertError);
          }

          // if user inputs an invalid postal code
          if (response === "bad_postal_code") {
            alert(badPostalCode);
          }

          // if data fetching was succesfull
          if (response.success && response.data.agents) {
            postnordLocations = response.data.agents;

            // hide error message if any
            $(".postalcode-section__error").hide();

            // loop through service points and add them to select
            var index = 1;
            $.each(response.data.agents, function(i, agent) {
              var address = `${agent.address1}, ${agent.zipCode}, ${agent.city}`;
              var title =
                `<div class="servicepoint"><span class="servicepoint-name">${agent.name}</span><span class="servicepoint-street">, ${address}</span></div>`;

              var liElm = `<li data-value="${agent.id}" data-option-id="${response.data.option_id}">${title}</li>`;

              $("#select-servicepoints")
                .append(liElm)
                .show();

              var searchButton = $(".postalcode-section__search-button");
              var selectButton = $(".postalcode-section__select-button");
              var selectWrap = $(".postalcode-section__select-wrap");

              selectWrap.show();
              searchButton.hide();
              selectButton.show();

              index++;
            });

            // set first service points as selected
            $("#select-servicepoints")
              .find("li:first-child")
              .addClass("selected");

            var selectedValue = $("#select-servicepoints")
              .find("li:first-child")
              .attr("data-value");

            $("#servicepoints-input").attr("value", selectedValue);
            $("#servicepoints-option-input").attr("value", response.data.option_id);
            $(".postalcode-section__message").show();
          } else {
            // show error message if any
            $(".postalcode-section__error").show();
          }
        },
        error: function(error) {
          $("body").removeClass("product-loading");
          alert("Något gick fel, försök igen");
        }
      });
    }
  });

  $("#select-servicepoints").on("click", function() {
    $(this).toggleClass("select-servicepoints--expanded");
  });

  /**
   * On update postal code remove selected address and checkout form
   */
  $("#servicepoints input[name=postalcode]").on("keyup", function() {
    postnordLocations = null;

    var searchButton = $(".postalcode-section__search-button");
    var postalcodeMessage = $(".postalcode-section__message");
    var selectWrap = $(".postalcode-section__select-wrap");

    selectWrap.hide();
    searchButton.show();
    postalcodeMessage.show();

    // hide error message if any
    $(".postalcode-section__error").hide();

    $("#shop-checkout-form").addClass("is-blurred");

    /**
     * Remove shop Checkout Form
     */
    $("#shop-checkout-form").addClass("is-loading");

    if ($("#select-servicepoints").is(":visible")) {
      $("#select-servicepoints")
        .html("")
        .hide();
      $("#servicepoints-input").attr("value", "");

      var searchButton = $(".postalcode-section__search-button");
      var selectButton = $(".postalcode-section__select-button");

      searchButton.show();
      selectButton.hide();

      $("#shop-checkout-form").addClass("is-blurred");
    }
  });

  /**
   * More info box slide toggle
   */

  $(".js-more-info-trigger").on("click", function() {
    // init var for translation
    var moreInfo = "Mer info";
    var lessInfo = "Mindre info";

    // Check which language
    if (siteLang === "NO") {
      moreInfo = "Mer info";
      lessInfo = "Mindre info";
    } else {
      moreInfo = "Mer info";
      lessInfo = "Mindre info";
    }
    $(this).text($(this).text() == moreInfo ? lessInfo : moreInfo);
    $(".postalcode-section__more-info").slideToggle();
  });

  /**
   * On change address from dropdown, remove shop checkout form
   */
  $(document).on("click", "#select-servicepoints li", function() {
    /**
     * Remove shop Checkout Form
     */
    $("#shop-checkout-form").addClass("is-loading");

    $(this).addClass("selected");
    $("#servicepoints-input").attr("value", $(this).attr("data-value"));
    $("#servicepoints-option-input").attr("value", $(this).attr("data-option-id"));

    // Move selected class to top of li
    $(this)
      .parent()
      .prepend(this);
    // Remove class selected from other li
    $(this)
      .siblings()
      .each(function() {
        $(this).removeClass("selected");
      });

    var postalcodeMessage = $(".postalcode-section__message");
    var selectButton = $(".postalcode-section__select-button");

    selectButton.show();
    postalcodeMessage.show();

    $("#shop-checkout-form").addClass("is-blurred");
  });

  /**
   * Set pickup point in order and
   * Render shop Checkout based on newly selected delivery address
   *
   * @param string nonce
   * @param object agents
   */
  function choosePickupPoint(nonce, agent, option_id) {
    if (agent && option_id) {
      $.ajax({
        url: adminURL,
        method: "post",
        data: {
          action   : "choose_pickup_point",
          nonce    : nonce,
          agent    : agent,
          option_id: option_id,
        },
        beforeSend: function() {
          $("body").addClass("product-loading");
        },
        success: function(response) {
          if (response.data.show_form && response.data.svea_form) {
            $("body").removeClass("product-loading");
            /**
             * Hide submit buttons
             */
            $("#servicepoints [type=submit]").hide();
            $(".postalcode-section__message").hide();

            $("#select-servicepoints").removeClass(
              "select-servicepoints--expanded"
            );

            /** Add Svea form iframe */
            $('#svea-checkout-form').html(response.data.svea_form);

            /** Add Vipps form */
            if (response.data.vipps_form) {
              $('#vipps-checkout-form').html(response.data.vipps_form);
            }

            $("#shop-checkout-form").removeClass("is-loading").removeClass("is-blurred");
          }

          // FIXME: Handle if we don't get checkout form here
        }
      });
    }
  }
});
