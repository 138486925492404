import Mustache from "mustache";

(function($) {
  // toggle dropdown on keyup
  $("#search-prod-input").on(
    "keyup input focus",
    delay(function(e) {
      var searchString = $(this)
        .val()
        .replace(/\s+/g, " ");
      makeClerkSearch(searchString);
    }, 300)
  );

  /**
   * Make search result page
   *
   * @param {string} searchString
   */
  function makeClerkSearch(searchString) {
    var loading = $(".search-loading");
    var searchWrapper = $(".search-result-wrap");

    // Display search result dropdown if user type more than 1 letter/character
    if (searchString.length > 1) {
      searchWrapper.addClass("active");

      /**
       * Used when to make multiple ajax request
       * and display results after all request completes
       */
      $.when(
        // make ajax call to get categories
        $.ajax({
          url: clerkApi.categoriesSearch,
          type: "GET",
          crossDomain: true,
          dataType: "jsonp",
          data: {
            key: clerkApi.publicKey,
            limit: 16,
            query: searchString,
            attributes: ["name", "url"] // fields we need from clerk
          }
        }),

        // make ajax call to get products
        $.ajax({
          url: clerkApi.predictiveSearch,
          type: "GET",
          crossDomain: true,
          dataType: "jsonp",
          data: {
            key: clerkApi.publicKey,
            limit: 16,
            query: searchString,
            attributes: ["name", "price", "image", "url"] // fields we need from clerk
          }
        })
      ).done(function(categoryList, productList) {
        // when ajax request completes, hide loading
        loading.hide();

        // compose search data and pass to Mustache template engine
        var categories = categoryList[0].categories;
        var products = productList[0].product_data;
        var searchData = {
          hasCategories: categories.length ? true : false,
          hasProducts: products.length ? true : false,
          categoryList: categories,
          productList: products,
          results: categories.length || products.length ? true : false
        };

        var searchResultWrapper = $("#search-results");
        var searchResultsTemplate = $("#template-search-result").html();
        Mustache.parse(searchResultsTemplate);
        var rendered = Mustache.render(searchResultsTemplate, searchData);
        searchResultWrapper.html(rendered);
      });
    } else {
      searchWrapper.removeClass("active");
    }
  }

  // sets delay from https://goo.gl/eQHzWv
  function delay(callback, ms) {
    var timer = 0;
    return function() {
      var context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function() {
        callback.apply(context, args);
      }, ms || 0);
    };
  }
})(jQuery);
