$(document).ready(function ($) {
  if (localStorage.getItem("cart_counter")) {
    var count = localStorage.getItem("cart_counter");
    refreshCartcount(count);
  }

  /**
   * Increment/decrement quantity
   */
  $(".qty-buttons button").on("click", function () {
    var me = $(this).attr("class");
    var input = $(this).closest(".qty-buttons").find("input");
    var oldQuantity = $(input).val();

    if (me === "qty-up") {
      var newQuantity = parseFloat(oldQuantity) + 1;
    } else {
      if (oldQuantity > 1) {
        var newQuantity = parseFloat(oldQuantity) - 1;
      } else {
        newQuantity = 1;
      }
    }

    // set value on quantity input
    $(input).val(newQuantity);

    // edit addtocart form
    var elm = $(this).parents(".addtocart");
    var form = elm.find("form");

    var regular_price = form.find("input[name=price]").val();
    form.find("input[name=quantity]").attr("value", newQuantity);

    // update price when quantity changes
    var discount = form.find("input[name=discount]").val();
    if (discount > 0) {
      var rate = form.find("input[name=discount]").val();
      elm
        .find(".regular-price-crossed")
        .text(regular_price * newQuantity + " kr");
    } else {
      var rate = regular_price;
    }

    /**
     * Handle dynamic discounts based on quantity
     */
    var dynamic_discount_quantity = form
      .find("input[name=dynamic_discount_quantity]")
      .val();

    if (dynamic_discount_quantity) {
      dynamic_discount_quantity = JSON.parse(dynamic_discount_quantity);
      var min = dynamic_discount_quantity.min_quantity;
      var max = dynamic_discount_quantity.max_quantity;
      var percent = dynamic_discount_quantity.discount_amount;
      var price_wrapper = elm.find(".price-wrapper");

      // if dynamic price discount catched
      if (newQuantity >= min && newQuantity <= max) {
        price_wrapper.addClass("discount");
        price_wrapper.prepend(
          '<span class="regular-price-crossed">' +
            regular_price * newQuantity +
            " kr</span>"
        );
        rate = form.find("input[name=price]").val() * (1 - percent / 100);
      } else {
        price_wrapper.removeClass("discount");
        $(".regular-price-crossed").remove();
      }
    }

    var amount = newQuantity * rate;
    elm.find(".price").text(amount);
  });

  /**
   * Toogle wrap with add to cart button on hover product card
   * click function is used for mobile
   */
  $(document).on("mouseover", ".product-card", function () {
    $(this).find(".product-image-wrap").addClass("active");
  });
  $(document).on("mouseleave", ".product-card", function () {
    $(this).find(".product-image-wrap").removeClass("active");
  });

  /**
   * Add product to cart
   */
  $(document).on("submit", ".addtocart form", function (e) {
    e.preventDefault();

    /**
     * Set this form as var
     */
    var form = $(this);

    /**
     * Collect product data
     *
     * @var {object} data
     */
    // prettier-ignore
    var data = {
      action: 'wc_ajax_add_to_cart',
      product_id: parseInt(form.find('input[name=product]').val()),
      product_quantity: parseInt(form.find('input[name=quantity]').val()),
      product_title: form.find("input[name=name]").val(),
      product_price: parseFloat(form.find("input[name=price]").val()),
      product_discountprice: parseFloat(form.find("input[name=discount]").val()),
      product_category: form.find("input[name=category]").val(),
      product_category_ids: form.find("input[name=category_ids]").val(),
      product_image: form.find("input[name=image]").val(),
      product_link: form.find("input[name=link]").val(),
      related_product: form.find("input[name=related_product]").val() ? parseInt(form.find("input[name=related_product]").val()) : 0,
    }

    /**
     * Collect product data for Google Tag Manger
     *
     * @var {object} data
     */
    // prettier-ignore
    var gtm_data = {
      id: data.product_id,
      name: data.product_title,
      price: data.product_price,
      category: data.product_category,
      quantity: data.product_quantity,
      brand: form.find("input[name=brand]").val(),
    };

    // initiate ajax add to cart
    $.ajax({
      url: adminURL,
      method: "POST",
      data: data,
      beforeSend: function () {
        /** Add loading classes */
        addLoadingState();

        /**
         * Clear related products in modal
         */
        $("#related-product-wrap").empty();
      },
      success: function (response) {
        /** Remove loading classes */
        removeLoadingState();

        /**
         * If response has failed for some reason
         */
        if (!response.success) {
          alert(
            "Hoppsan nÃ¥t gick fel, vÃ¤nligen fÃ¶rsÃ¶k igen. Om felet kvarstÃ¥r, kontakta info@rfsu.com"
          );
        }

        /**
         * If response success
         */
        if (response.success) {
          /** Show modal */
          addToCartModal(form);

          /**
           * Update the HTML in modal if related products
           *
           * @var {string} response.related_product_html Related product in HTML
           */
          if (response.related_product_html) {
            $("#related-product-wrap").html(response.related_product_html);
          }

          /** Add dynamic discount popup content if available */
          if (response.valentine_popup_content) {
            $("#valentine-popup-content")
              .html(response.valentine_popup_content)
              .show();
            $("#related-product-content").hide();
          } else {
            $("#valentine-popup-content").html("").hide();
            $("#related-product-content").show();
          }

          /**
           * Update the counter on cart widget
           *
           * @var {int} response.products_quantity Number of different products in cart
           */
          refreshCartcount(response.products_quantity);

          /**
           * Update the cart summary
           *
           * @var {int} response.products_quantity Number of different products in cart
           * @var {int} response.carttotal Total amount of cart (excl. shipping)
           * @var {int} response.carttotal_inc_freight Total amount of cart (ink. shipping)
           */
          refreshCartSummary(
            response.products_quantity,
            response.carttotal,
            response.carttotal_inc_freight
          );

          /**
           * Update the "superdeals products" (below cart data) present on Cart page
           */
          if ($("body.woocommerce-cart").length > 0) {
            updateSuperdealProducts();
          }

          /**
           * Send data to Google Tag Manager
           *
           * @var {array} gtm_data Array of data with product details
           */
          addtoCartGTM(gtm_data);
        }
      },
      error: function (error) {
        /** Remove loading classes */
        removeLoadingState();
      },
    });
  });

  /**
   * Add multiple products to cart
   */
  $(document).on("click", ".add-to-cart-group-products", function (e) {
    e.preventDefault();
    var products = new Array();
    var groupProductsElm = $(this).parents(".group-products");
    var forms = groupProductsElm.find("form");

    /**
     * Go through products and push them into products array
     */
    forms.each(function () {
      var form = $(this);
      var data = serializeFormData(form);
      products.push({
        product: parseInt(data.product),
        sku: data.sku,
        price: parseFloat(data.price),
        discount: parseFloat(data.discount),
        name: data.name,
        image: data.image,
        desc: data.desc,
        quantity: parseInt(data.quantity),
        link: data.link,
        brand: data.brand,
      });
    });

    /**
     * Collect product data
     */
    // prettier-ignore
    var data = {
      action: 'wc_ajax_add_multiple_to_cart',
      products: products
    }

    /**
     * Initiate ajax add to cart
     */
    $.ajax({
      url: adminURL,
      method: "POST",
      data: data,
      beforeSend: function () {
        /** Add loading classes */
        addLoadingState();
      },
      success: function (response) {
        /** Remove loading classes */
        removeLoadingState();

        /**
         * Update the counter on cart widget
         *
         * @var {int} response.products_quantity Number of different products in cart
         */
        refreshCartcount(response.products_quantity);

        /**
         * Update the cart summary
         *
         * @var {int} response.products_quantity Number of different products in cart
         * @var {int} response.carttotal Total amount of cart (excl. shipping)
         * @var {int} response.carttotal_inc_freight Total amount of cart (ink. shipping)
         */
        refreshCartSummary(
          response.products_quantity,
          response.carttotal,
          response.carttotal_inc_freight
        );

        /**
         * Add products to GTM
         */
        $.each(data.products, function (key, value) {
          var gtm_data = {
            id: value.product,
            name: value.name,
            price: value.price,
            category: "",
            quantity: value.quantity,
            brand: value.brand,
          };
          addtoCartGTM(gtm_data);
        });
      },
      error: function (error) {
        /** Remove loading classes */
        removeLoadingState();
      },
    });
  });

  /**
   * Remove product from cart
   */
  $(".cart-items-wrapper").on("click", ".remove-product", function (e) {
    e.preventDefault();

    /** If remove link from gift wrapper items is clicked, remove it */
    if ($(e.target).attr("data-gift-wrapper") == 1) {
      /** @type string Gift wrapper option */
      var option = "remove";

      /** @type string CSRF token */
      var nonce = $("input[name=item_gift_wrapper_nonce]").val();

      /** Handle gift wrapper option */
      handleGiftWrapperOption(option, nonce);

      return false;
    }

    /**
     * Collect product data
     */
    // prettier-ignore
    var data = {
      action: 'wc_ajax_remove_from_cart',
      product_id: parseInt($(this).parents('li[class^="cart-item"]').data('id')),
    }

    /**
     * Collect product data for Google Tag Manger
     */
    // prettier-ignore
    var gtm_data = {
      id: data.product_id,
      name: $(this).parents('li[class^="cart-item"]').data('name'),
      price: parseFloat($(this).parents('li[class^="cart-item"]').data('price')),
      category: $(this).parents('li[class^="cart-item"]').data('category'),
      quantity: parseInt($(this).parents('li[class^="cart-item"]').data('quantity')),
      brand: $(this).parents('li[class^="cart-item"]').data('brand')
    };

    /**
     * Initiate ajax remove from cart
     */
    $.ajax({
      url: adminURL,
      method: "POST",
      data: data,
      beforeSend: function () {
        /** Add loading classes */
        addLoadingState();
      },
      success: function (response) {
        refreshCartcount(response.products_quantity);

        /** Add GTM data for removing cart items */
        removefromCartGTM(gtm_data);

        window.location.reload();
      },
      error: function (error) {
        /** Remove loading classes */
        removeLoadingState();
      },
    });
  });

  /**
   * Modify quantity of product in cart
   */
  $(".cart-items-wrapper").on(
    "click",
    ".qty-buttons-cart button",
    function (e) {
      e.preventDefault();

      /**
       * Get input values
       */
      var me = $(this).attr("class");
      var input = $(this).closest(".qty-buttons-cart").find("input");
      var oldValue = $(input).val();

      /**
       * Change input value
       */
      if (me === "qty-up") {
        var newValue = parseFloat(oldValue) + 1;
      } else {
        if (oldValue > 1) {
          var newValue = parseFloat(oldValue) - 1;
        } else {
          newValue = 1;
        }
      }

      /**
       * Collect product data of modified product
       *
       * @var {object} data
       */
      // prettier-ignore
      var data = {
      action: 'wc_ajax_modify_quantity',
      product_id: parseInt($(this).parents('li[class^="cart-item"]').data('id')),
      product_quantity: newValue,
      regularprice: parseInt($(this).parents('li[class^="cart-item"]').data("regularprice")),
      price: parseInt($(this).parents('li[class^="cart-item"]').data("price")),
    }

      /**
       * Initiate ajax modify product quantity,
       * only if the quantity has changed
       */
      if (newValue != oldValue) {
        $.ajax({
          url: adminURL,
          method: "POST",
          data: data,
          beforeSend: function () {
            /** Add loading classes */
            addLoadingState();
          },
          success: function (response) {
            refreshCartcount(response.products_quantity);

            window.location.reload();
          },
          error: function (error) {
            /** Remove loading classes */
            removeLoadingState();
          },
        });
      }
    }
  );

  /* set cookies */

  $("#free-sample-chkbox").change(function () {
    var product = $(this).prop("checked") ? 1 : 0;
    var nonce = $(this).attr("data-nonce");

    setCookie("cart_sample_product", product, 1);

    $.ajax({
      url: adminURL,
      method: "post",
      data: {
        action: "add_sample_product",
        nonce: nonce,
        product: product,
      },
      success: function () {},
      error: function () {},
    });
  });

  /** Gift Wrapper Option */
  $("#gift-wrapper-option").on("change", function (e) {
    /** @type string Gift wrapper option */
    var option = "remove";
    if ($(e.target).is(":checked")) {
      option = "add";
    }

    /** @type string CSRF token */
    var nonce = $("input[name=gift_wrapper_nonce]").val();

    /** Handle gift wrapper option */
    handleGiftWrapperOption(option, nonce);
  });

  /**
   * Handle Gift Wrapper Option
   * @param {string} option
   * @param {string} nonce
   */
  function handleGiftWrapperOption(option, nonce) {
    /** @type object AJAX request data */
    var data = {
      action: "handle_gift_wrapper_option",
      option: option,
      nonce: nonce,
    };

    $.ajax({
      url: adminURL,
      method: "POST",
      data: data,
      beforeSend: function () {
        /** Show loading screen when gift wrapper checkox changes */
        $("body").addClass("product-loading");
      },
      success: function (response) {
        /** If gift wrapper added to cart, reload */
        if (response.success && response.data.reload) {
          window.location.reload();
        }
      },
      error: function (error) {
        // handle error here
      },
    });
  }
});

/**
 * Change text on Product card addtocart-button
 * depending on screen size
 */
$(window).on("load resize", function (e) {
  e.preventDefault();
  if (siteLang === "NO") {
    var cartMobile = "Legg i handlekurv";
    var cartDesktop = "KjÃ¸p";
  } else {
    var cartMobile = "LÃ¤gg till i varukorg";
    var cartDesktop = "KÃ¶p";
  }
  var addToCartButton = $(".product-buy-wrap .addtocart").find(
    'input[type="submit"]'
  );
  // If mobile
  if (window.matchMedia("(max-width: 1023px)").matches) {
    if (addToCartButton.val() == cartDesktop) {
      $(addToCartButton).val(cartMobile);
    }
  } else {
    if (addToCartButton.val() == cartMobile) {
      $(addToCartButton).val(cartDesktop);
    }
  }
});

/**
 * Refresh the cart widget quantity counter
 *
 * @var {int} quantity Number of different products in cart
 */
function refreshCartcount(quantity) {
  if (quantity == 0) {
    $(".cart-widget").addClass("empty");
    // Saves the value in cart
    localStorage.removeItem("cart_counter");
  } else {
    // Saves the value in cart
    localStorage.setItem("cart_counter", quantity);
    $(".cart-widget-counter").html(quantity);
    $(".cart-widget").removeClass("empty");
  }
}

/**
 * Refresh the cart summary data on cart page
 *
 * @var {int} no_items Number of different products in cart
 * @var {int} total Cart total (excl. shipping)
 * @var {int} total_freight Cart total (ink. shipping)
 */
function refreshCartSummary(no_items, total, total_freight) {
  $(".cart-summary .product-quantity").text(no_items + " st");
  $(".cart-summary .product-total").text(total + " kr");
  $(".cart-summary .cart-sum .price-sum").text(total_freight + " kr");
}

/**
 * Function to create key value pair from form data
 *
 * @var {array} form
 */
function serializeFormData(form) {
  var result = {};
  jQuery.each(form.serializeArray(), function () {
    result[this.name] = this.value;
  });
  return result;
}

/**
 * Add loading state
 */
function addLoadingState() {
  $("body").addClass("cart-loading");
  if ($(".cart-summary").length) {
    $(".cart-summary").addClass("loading");
  }
}

/**
 * Remove loading state
 */
function removeLoadingState() {
  $("body").removeClass("cart-loading");
  if ($(".cart-summary").length) {
    $(".cart-summary").removeClass("loading");
  }
}

/**
 * Handle the modal that opens
 * when user adds product to cart
 */
function addToCartModal(form) {
  $("body").addClass("modal-addtocart-active");
  // clear content
  $(".modal-addtocart .modal .img-wrap .discount-badge").remove();
  $(".modal-addtocart .modal .img-wrap .green").remove();
  $(".modal-addtocart .modal .img-wrap .heart").remove();
  $(".modal-addtocart .modal .product-price").removeClass("discounted");
  var sku = form.find("input[name=sku]").val();
  var price = form.find("input[name=price]").val();
  var isonsale = form.find("input[name=isonsale]").val();
  var discount = form.find("input[name=discount]").val();
  var name = form.find("input[name=name]").val();
  var brand = form.find("input[name=brand]").val();
  var desc = form.find("input[name=desc]").val();
  var quantity = form.find("input[name=quantity]").val();
  var image = form.find("input[name=image]").val();
  var productId = form.find("input[name=product]").val();
  var relatedBadgeText = form.find("input[name=related_badge_text]").val();
  var relatedBadgeClass = form.find("input[name=related_badge_class]").val();
  var dynamic_discount_quantity = form
    .find("input[name=dynamic_discount_quantity]")
    .val();

  $(".modal-addtocart .modal .added-to-cart .product-name").text(brand + name);
  $(".modal-addtocart .modal .added-to-cart .product-price").text(
    price * quantity
  );
  $(".modal-addtocart .modal .added-to-cart .product-sku").text(sku);
  $(".modal-addtocart .modal .added-to-cart .product-desc").text(desc);
  if (quantity > 1) {
    $(".modal-addtocart .modal .added-to-cart .product-quantity").show();
    $(".modal-addtocart .modal .added-to-cart .product-quantity").text(
      quantity + " st "
    );
  } else {
    $(".modal-addtocart .modal .added-to-cart .product-quantity").hide();
  }
  $(".modal-addtocart .modal .added-to-cart .img-wrap img").attr("src", image);

  // if product is discounted, add additional info to modal
  if (discount > 0 && isonsale) {
    var discount_amount = Math.round((1 - discount / price) * 100);
    $(".modal-addtocart .modal .product-price").addClass("discounted");

    $(".modal-addtocart .modal .added-to-cart .product-price").html(
      '<span class="discount-regular-price">' +
        price * quantity +
        ' kr</span><span class="price">' +
        discount * quantity +
        "</span>"
    );
  }

  if (relatedBadgeText && relatedBadgeClass == "") {
    $(".modal-addtocart .modal .img-wrap img").after(
      '<span class="info-badge green">' + relatedBadgeText + "</span>"
    );
  }

  var productCard = form.parents(".product-card");

  /** Check for info badge */
  var infoBadge = productCard.find(".info-badge");
  $(".modal-addtocart .modal .img-wrap .info-badge").remove();
  if (infoBadge.length) {
    $(".modal-addtocart .modal .img-wrap img").after(infoBadge.clone());
  }

  /** Check for discount badge */
  var discountBadge = productCard.find(".discount-badge");
  $(".modal-addtocart .modal .img-wrap .discount-badge").remove();
  if (discountBadge.length) {
    $(".modal-addtocart .modal .img-wrap img").after(discountBadge.clone());
  }

  /**
   * Handle dynamic discounts based on quantity
   */
  if (dynamic_discount_quantity) {
    dynamic_discount_quantity = JSON.parse(dynamic_discount_quantity);
    var min = dynamic_discount_quantity.min_quantity;
    var max = dynamic_discount_quantity.max_quantity;
    var percent = parseInt(dynamic_discount_quantity.discount_amount);
    var discount_amount = parseInt(price) * (1 - percent / 100);

    // if dynamic price discount catched
    if (quantity >= min && quantity <= max) {
      $(".modal-addtocart .modal .product-price").addClass("discounted");
      $(".modal-addtocart .modal .added-to-cart .product-price").html(
        '<span class="discount-regular-price">' +
          price * quantity +
          ' kr</span><span class="price">' +
          discount_amount * quantity +
          "</span>"
      );
    }
  }

  var productSlot = $(".modal-addtocart #popup-product-list");
  var iteration = parseInt(productSlot.attr("data-iteration"));
  if (iteration > 1) {
    // send ajax request when popup activates, to get recommended products
    $.ajax({
      url: adminURL,
      method: "POST",
      data: {
        action: "popup_recommended_products",
        product_id: productId,
      },
      beforeSend: function () {
        productSlot.css("opacity", 0.15);
        $(".other-bought-heading").css("opacity", 0.15);
      },
      success: function (response) {
        if (response.success && response.data.have_products) {
          $(".other-bought-heading").css("opacity", 1);
          productSlot.html(response.data.html).css("opacity", 1);
        } else {
          productSlot.html("");
          $(".other-bought-heading").css("opacity", 0);
        }
      },
      error: function (error) {},
    });
    // increase iteration of popup
    productSlot.attr("data-iteration", iteration + 1);
  }
}

// set cookie function
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
