var sg_show_countdown_container = true;

var sg_add_countdown_timer_192512568 = setInterval(function () {
  if ($(".product-info .product-usplist").length > 0) {
    clearInterval(sg_add_countdown_timer_192512568);

    var language_ele = document.querySelector('.product-info .product-usplist');
    var language = language_ele.getAttribute('data-lang');
    var msg_txt = "Vi skickar din order idag om du beställer inom:";
    var hour_txt = 'TIM';

    if (language == 'no') {
      msg_txt = "Vi sender din ordre i dag om du bestiller innen:";
      hour_txt = 'T';
    }

    // Get todays date in weekday (monday = 1)
    var todaysWeekday = new Date().getDay();
    //todaysWeekday = 1;
    if (todaysWeekday >= 1 && todaysWeekday <= 5) {
      $(".product-info .product-usplist").after(
        "<div class='sg-countdown-outer-container' style='display:none;'>" +
        "<div class='sg-delivery-text'>" + msg_txt + "</div>" +
        "<div class='sg-countdown-container'>" +
        '<ul class="sg-countdown">' +
        '<li class="sg-countdown-item">' +
        '<span class="sg-countdown-value" id="sg-countdown-hours"> </span>' +
        '<span class="sg-countdown-unit">' + hour_txt + '</span>' +
        '</li>' +
        '<li class="sg-countdown-item">' +
        '<span class="sg-countdown-value" id="sg-countdown-minutes"> </span>' +
        '<span class="sg-countdown-unit">min</span>' +
        '</li>' +
        '<li class="sg-countdown-item">' +
        '<span class="sg-countdown-value" id="sg-countdown-seconds"> </span>' +
        '<span class="sg-countdown-unit">sek</span>' +
        '</li>' +
        '</ul>' +
        "</div>" +
        "</div>"
      );

      // Update the count down every 1 second
      var countdown_timer = setInterval(function () {

        // Get todays date and time in Hours
        var todaysTime = new Date();
        todaysTime.getHours();

        // Set the time we're counting down to in Hour, minute, seconds
        var nextTime = new Date();
        nextTime.setHours(13, 0, 0);

        if (language == 'no') {
          nextTime.setHours(12, 0, 0);
        }

        // add a day
        nextTime.setDate(nextTime.getDate() + 1);

        // time remaining (the result in milliseconds)
        var remainingTime = nextTime - todaysTime;

        var dayInMilliseconds = 86400000;
        if (remainingTime > dayInMilliseconds) {
          remainingTime = remainingTime - dayInMilliseconds;
        }

        // remaining time is 6 hours or less
        if (remainingTime <= 21600000) {
          if (sg_show_countdown_container == true) {
            $(".sg-countdown-outer-container").css("display", "block");
            sg_show_countdown_container = false;
          }
          // Time calculations from milliseconds to time
          var ms = remainingTime % 1000;
          remainingTime = (remainingTime - ms) / 1000;
          var secs = remainingTime % 60;
          remainingTime = (remainingTime - secs) / 60;
          var mins = remainingTime % 60;
          var hrs = (remainingTime - mins) / 60;
          $("#sg-countdown-hours").html(hrs);
          $("#sg-countdown-minutes").html(mins);
          $("#sg-countdown-seconds").html(secs);

        } else {
          clearInterval(countdown_timer);
          $(".sg-countdown-outer-container").css("display", "none");
          //$("#addToBasketForm .product-item__wish").before("<div class='sg-evening-text'>" + sg_delivery_text_NO_counter + "</div>");
        }
      }, 1000);
    } else if (todaysWeekday == 6 || todaysWeekday == 0) {
      //$("#addToBasketForm .product-item__wish").before("<div class='sg-weekend-text'>" + sg_delivery_text_NO_counter + "</div>");
      $(".sg-countdown-container").css("display", "none");
    }
  }
}, 10);


$(document).ready(function () {
  setTimeout(function () {
    clearInterval(sg_add_countdown_timer_192512568);
  }, 5000);
});
