var _ = require("lodash");
// -------------------------------------
// controlls the mobile menu icon burger
// -------------------------------------

$(document).ready(function() {
  $("#nav-icon").click(function() {
    // Toggles the navicon burger appearance
    $(this).toggleClass("open");
    // Toggles the overlay when opening/closing the menu
    $("body").toggleClass("menu-active");
  });

  // Close mobile menu related elements. Debounce to not trigger resize too often.
  function menuState() {
    if ($(window).width() >= 1280) {
      $("#nav-icon").removeClass("open");
      $("body").removeClass("menu-active");
    }
  }
  jQuery(window).on("resize", _.debounce(menuState, 300));
});
